import React, { useState } from "react";
import { Scanner } from "@yudiel/react-qr-scanner";

import Person from "../../assets/images/person.png";

import { Home } from "react-feather";
import { useNavigate } from "react-router-dom";

import { saveActivityData } from "../../services/Activity";

const MemberPage = ({ initialData }) => {
  if (initialData == "") {
    window.location.href = "/";
  }
  const navigate = useNavigate();

  const [scanDevice, setScanDevice] = useState(false);
  const [codeDevice, setCodeDevice] = useState(undefined);

  let id = "";
  if (initialData !== "") {
    for (let i = 0; i < initialData?._id?.length; i++) {
      if (!isNaN(initialData?._id[i])) {
        id += initialData?._id[i];
      }
    }
  }

  const handleActionDevice = () => {
    setScanDevice(true);
  };

  const reciveDevice = async (code) => {
    try {
      const dvCode = code[0]?.rawValue.split(" ");

      // send data To Database for save

      console.log(dvCode);

      const data = {
        // deviceCode: dvCode,
        deviceName: `${dvCode[1]} ${dvCode[2]}`,
        playerName: initialData?.username,
      };

      await saveActivityData(data);

      setCodeDevice(`${dvCode[1]} ${dvCode[2]}`);

      setScanDevice(false);
    } catch (error) {
      navigate("/");
    }
  };

  const options = {
    day: "numeric",
    month: "short",
    year: "numeric",
  };

  const date = new Date(initialData?.createAt);
  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(date);

  return (
    <div className="w-full p-5">
      {/* <div className="moon" /> */}
      <div
        className="bg-black w-fit p-5 rounded-full block cursor-pointer absolute z-"
        onClick={() => navigate("/")}>
        <Home color="white" />
      </div>
      <div className="flex flex-col items-center">
        <p className="text-4xl lg:text-6xl font-bold text-[#EE803A] text-shadow z-10">
          ยินดีต้อนรับเข้าสู่
        </p>
        <p className="text-2xl font-semibold text-white">Enter Space</p>

        {scanDevice ? (
          <div className="overflow-hidden  shadow-[#EE803A] my-10 w-52 h-52 lg:h-96 lg:w-96">
            <Scanner
              onScan={(result) => {
                reciveDevice(result);
              }}
            />
          </div>
        ) : (
          <div className="rounded-full overflow-hidden border border-[#EE803A] shadow-[#EE803A] my-10 w-52 h-52 lg:h-96 lg:w-96">
            <img
              src={
                !initialData
                  ? Person
                  : `${process.env.REACT_APP_SERVER_NODE}/public/profile/${initialData?.username}/${initialData?.username}-profile.jpg`
              }
              alt="profile"
              className="shadow-2xl filter brightness-125 w-full h-full object-cover"
            />
          </div>
        )}

        <p className="uppercase text-5xl lg:text-6xl font-bold text-white">
          {initialData?.username}
        </p>
      </div>
      <div className="text-center my-5 text-white">
        <div>
          <p className="text-[#EE803A] text-shadow text-2xl lg:text-4xl">
            วันที่เข้าร่วม
          </p>
          <p className="text-white text-2xl lg:text-4xl mb-12">
            {formattedDate}
          </p>
        </div>
        {codeDevice ? (
          <div>
            <p className="text-[#EE803A] text-shadow text-2xl lg:text-4xl">
              Serial Code : {`${codeDevice}`}
            </p>
          </div>
        ) : (
          <button
            className="border border-white rounded-full px-20 py-10 button-start"
            onClick={() => handleActionDevice()}>
            <p className="text-lg lg:text-2xl text-center italic text-white drop-shadow-[1px_1px_1px_rgba(0,0,0,1)] ">
              เริ่มแสกนอุปกรณ์
            </p>
          </button>
        )}
      </div>
    </div>
  );
};

export default MemberPage;
